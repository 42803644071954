import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Set app element for accessibility

const API_KEY = process.env.GOOGLE_API_KEY; // Use the API key from the environment variable
const CHANNEL_ID = 'UCeZRqDU0e6m8XUq4DMQaKuA'; // Replace with your YouTube channel ID

const YouTubeCarousel = () => {
  const [videoDetails, setVideoDetails] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState('');
  const [modalStyle, setModalStyle] = useState({});
  const carouselRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 7) % videoDetails.length);
    }, 15000); // Rotate every 15 seconds

    return () => clearInterval(interval);
  }, [videoDetails.length]);

  useEffect(() => {
    const fetchVideoDetails = async () => {
      try {
        const response = await axios.get('https://www.googleapis.com/youtube/v3/search', {
          params: {
            key: API_KEY,
            channelId: CHANNEL_ID,
            part: 'snippet',
            order: 'date',
            maxResults: 50,
          },
        });
        const videoIds = response.data.items.map(item => item.id.videoId).filter(id => id);
        const videoResponse = await axios.get('https://www.googleapis.com/youtube/v3/videos', {
          params: {
            id: videoIds.join(','),
            key: API_KEY,
            part: 'snippet',
          },
        });
        setVideoDetails(videoResponse.data.items.map(item => ({
          id: item.id,
          title: item.snippet.title,
          thumbnail: item.snippet.thumbnails.high.url,
        })));
      } catch (error) {
        console.error('Error fetching video details:', error.response ? error.response.data : error.message);
      }
    };

    fetchVideoDetails();
  }, []);

  useEffect(() => {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.onYouTubeIframeAPIReady = () => {
      playerRef.current = new window.YT.Player('video-iframe', {
        events: {
          onStateChange: onPlayerStateChange,
        },
      });
    };
  }, []);

  const onPlayerStateChange = (event) => {
    if (event.data === window.YT.PlayerState.ENDED) {
      closeModal();
    }
  };

  const displayedVideos = videoDetails.slice(currentIndex, currentIndex + 7);

  const handleVideoClick = (videoId, e) => {
    const rect = e.target.getBoundingClientRect();
    setModalStyle({
      top: `${rect.top}px`,
      left: `${rect.left}px`,
      width: `${rect.width}px`,
      height: `${rect.height}px`,
      position: 'absolute',
      zIndex: 1000,
    });
    setCurrentVideoId(videoId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentVideoId('');
    setModalStyle({});
  };

  return (
    <div className="youtube-carousel-container">
      {videoDetails.length > 0 ? (
        <div className="youtube-carousel" ref={carouselRef}>
          {displayedVideos.map((video) => (
            <div
              className="youtube-carousel-item"
              key={video.id}
              onClick={(e) => handleVideoClick(video.id, e)}
              style={{
                backgroundImage: `url(${video.thumbnail})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '120px',
                height: '213px',
                cursor: 'pointer',
                borderRadius: '10px',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                position: 'relative',
              }}
            >
              <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'rgba(0, 0, 0, 0.6)',
                color: 'white',
                textAlign: 'center',
                lineHeight: '1.2em',
                wordWrap: 'break-word',
                padding: '10px',
                boxSizing: 'border-box',
              }}>
                {video.title}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>Loading videos...</p>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Video Modal"
        style={{
          overlay: {
            backgroundColor: 'transparent',
          },
          content: {
            ...modalStyle,
            borderRadius: '10px',
            background: 'rgba(0, 0, 0, 0.85)',
            padding: '0',
            overflow: 'hidden',
          },
        }}
      >
        <button onClick={closeModal} style={{ marginBottom: '10px', padding: '10px 20px', border: 'none', borderRadius: '5px', background: '#333', color: '#fff', cursor: 'pointer', position: 'absolute', top: '10px', right: '10px' }}>
          Close
        </button>
        {currentVideoId && (
          <iframe
            id="video-iframe"
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${currentVideoId}?autoplay=1&enablejsapi=1`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ position: 'relative' }}
          ></iframe>
        )}
      </Modal>
    </div>
  );
};

const HomePage = () => {
  const [reviews, setReviews] = useState([]);
  const [currentReview, setCurrentReview] = useState(0);

  useEffect(() => {
    // Assuming you have a function to load the reviews from an external source
    // Example:
    setReviews([
      {
        photo: '/path/to/photo1.jpg',
        name: 'John Doe',
        title: 'Senior Attorney',
        text: 'Whiteshoe Work is absolutely amazing! It generates legal documents that are structured and concise.',
      },
      // Add more reviews here...
    ]);
  }, []);

  const handleNextReview = () => {
    setCurrentReview((prevReview) => (prevReview + 1) % reviews.length);
  };

  const handlePrevReview = () => {
    setCurrentReview(
      (prevReview) => (prevReview - 1 + reviews.length) % reviews.length
    );
  };

  return (
    <div className="content-wrapper">
      <div className="recent-research">
        <div>
          <span>NEW DOWNLOAD:</span>
          <a href="/assets/LoperBrightImplications.pdf" target="_blank" rel="noopener noreferrer">
            60 Likely reversals after SCOTUS overturned Chevron deference in Loper Bright. Written for investment.
          </a>
        </div>
      </div>

      <YouTubeCarousel />

      <p className="black-text">Whiteshoe does AI for legal, financial, and other businesses. Our main product is legal text generation.</p>

      {/* Adding Long Mode section */}
      <div className='flex-item grey-box'>
        <h3 className='centered-title'>Legal Text Generation</h3>
        <p className='centered-text'>
          We generate complete legal documents using GPT4o with specialized RAG integration for your jurisdiction. These are competent, well organized, complete documents of the length specified in the prompt. For example, "Write a 20 page response to this complaint. Ms. X was unaware of the wrongful actions and etc. etc. etc." Our model uses a prompt pipeline for OpenAI models with extensive RAG integration for relevant caselaw and statutes.
        </p>
      </div>

    </div>
  );
};

export default HomePage;
