import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CSE } from '../../utils/CSE';
import ReactLoading from 'react-loading';
import whiteDocIcon from '../../assets/img/whitedoc.svg';
import blackDocIcon from '../../assets/img/blackdoc.svg';
import '../../assets/css/styles.css';
import { reviews as reviewData } from '../../utils/reviews';
import { parseMarkdown } from '../../utils';

function WorkPage() {
  const [ragInput, setRagInput] = useState('');
  const [ragOutput, setRagOutput] = useState('');
  const [usageCount, setUsageCount] = useState(0);
  const [ipAddress, setIpAddress] = useState('');
  const [selectedDemo, setSelectedDemo] = useState('');
  const [demoContent, setDemoContent] = useState('');
  const [tone, setTone] = useState('Professional');
  const [bodyOfLaw, setBodyOfLaw] = useState('USA');
  const [language, setLanguage] = useState('English');
  const [isShortLoading, setIsShortLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentReview, setCurrentReview] = useState(0);
  const [reviews, setReviews] = useState([]);
  const maxUsage = 3;
  const navigate = useNavigate();

  useEffect(() => {
    const connectToMongoDB = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/test-db`
        );
        if (response.ok) {
          const data = await response.json();
          console.log('Connected to MongoDB:', data.users);
        } else {
          console.error('Failed to connect to MongoDB:', response.status);
        }
      } catch (error) {
        console.error('Error connecting to MongoDB:', error.message);
      }
    };

    connectToMongoDB();
  }, []);

  useEffect(() => {
    const fetchIpAndUsage = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/get-ip`
        );
        console.log('API Response:', data);

        if (data && data.usageCount !== undefined && data.ipAddress !== undefined) {
          setIpAddress(data.ipAddress);
          setUsageCount(data.usageCount);
        } else {
          console.error('Invalid response data:', data);
        }
      } catch (error) {
        console.error('Error fetching IP and usage:', error);
      }
    };

    fetchIpAndUsage();
  }, []);

  const handleRagInputChange = (e) => {
    setRagInput(e.target.value);
  };

  const handleRagSubmit = async (e) => {
    e.preventDefault();
    if (usageCount >= maxUsage) {
      toast.error('You have reached the maximum number of uses.');
      return;
    }

    setIsShortLoading(true);

    try {
      const ragInput = e.target.elements.inputText.value;

      // Function to check if the input implicates one or more legal questions using GPT-4
      const isLegalQuestion = async (text) => {
        const response = await axios.post(
          `https://api.openai.com/v1/chat/completions`,
          {
            model: 'gpt-4o',
            messages: [
              { role: 'system', content: 'You are a helpful assistant.' },
              {
                role: 'user',
                content: `Does the following text implicate one or more legal questions? Respond with "yes" or "no":\n\n"${text}"`,
              },
            ],
            max_tokens: 10,
            n: 1,
            stop: null,
          },
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
              'Content-Type': 'application/json',
            },
          }
        );

        const answer = response.data.choices[0].message.content.trim().toLowerCase();
        return answer === 'yes';
      };

      // Check if the input implicates legal questions using GPT-4
      const legalQuestion = await isLegalQuestion(ragInput);

      let shortResponse;
      if (legalQuestion) {
        let externalInfoTexts = [];
        let CSEData = '';
        Object.keys(CSE).map((key) => {
          if (key === bodyOfLaw) {
            CSEData = CSE[key];
            return null;
          }
          return null;
        });

        // Make the CSE request if the input is a legal question
        let externalInfoResponse = await axios.get(
          `https://www.googleapis.com/customsearch/v1?q=${ragInput}&key=${process.env.GOOGLE_API_KEY}&cx=${CSEData}`
        );

        let externalInfoText = externalInfoResponse.data.items
          .map((item) => item.snippet)
          .join('\n');
        externalInfoTexts.push(externalInfoText);
        let combinedExternalInfoText = externalInfoTexts.join('\n');

        shortResponse = await axios.post(
          `https://api.openai.com/v1/chat/completions`,
          {
            model: 'gpt-4o',
            messages: [
              { role: 'system', content: 'You are a helpful assistant.' },
              {
                role: 'user',
                content: `${ragInput}\n\nYou are a ${tone} lawyer who knows the law of ${bodyOfLaw} and writes in ${language}.\n\nIncorporate the following external information into the response:\n${combinedExternalInfoText}\n\nPlease provide a concise response with key points in less than 800 tokens.`,
              },
            ],
            max_tokens: 800,
            n: 1,
            stop: ["\n\n", "End of text"], // Ensure coherence
          },
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
              'Content-Type': 'application/json',
            },
          }
        );
      } else {
        // Directly pass the input to the GPT-4 API if it's not a legal question
        shortResponse = await axios.post(
          `https://api.openai.com/v1/chat/completions`,
          {
            model: 'gpt-4o',
            messages: [
              { role: 'system', content: 'You are a helpful assistant.' },
              {
                role: 'user',
                content: ragInput,
              },
            ],
            max_tokens: 800,
            n: 1,
            stop: ["\n\n", "End of text"], // Ensure coherence
          },
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
              'Content-Type': 'application/json',
            },
          }
        );
      }

      let shortAnswer = shortResponse.data.choices[0].message.content;
      setRagOutput(shortAnswer);
      setIsShortLoading(false);

      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/increment-usage`,
        { ip: ipAddress }
      );
    } catch (error) {
      console.error('Error generating RAG output:', error);
      alert('Error generating output. Please try again later.');
      setIsShortLoading(false);
    } finally {
      setUsageCount(usageCount + 1);
    }
  };

  // Function to fetch CSE results (replace with actual CSE implementation)
  const fetchCSEResults = async (query, CSEData) => {
    const response = await axios.get(
      `https://www.googleapis.com/customsearch/v1?q=${query}&key=${process.env.GOOGLE_API_KEY}&cx=${CSEData}`
    );
    return response.data.items.map(item => item.snippet).join('\n');
  };

  const handleDemoChange = async (e) => {
    const selectedDemo = e.target.value;
    setSelectedDemo(selectedDemo);

    if (selectedDemo) {
      try {
        const response = await fetch(`/demo-documents/${selectedDemo}.txt`);
        const text = await response.text();
        const htmlContent = parseMarkdown(text);
        setDemoContent(htmlContent);
      } catch (error) {
        console.error('Error loading demo document:', error);
        setDemoContent('Error loading demo document.');
      }
    } else {
      setDemoContent('');
    }
  };

  const handleSignup = () => {
    navigate('/signup')
  }

  const handleNextReview = () => {
    setCurrentReview((prevReview) => (prevReview + 1) % reviews.length);
  };

  const handlePrevReview = () => {
    setCurrentReview(
      (prevReview) => (prevReview - 1 + reviews.length) % reviews.length
    );
  };

  useEffect(() => {
    setReviews(reviewData);
  }, []);

  return (
    <div className='content-wrapper'>
      <main className='main'>
        <div className='flex-container'>
          <div className='flex-item grey-box'>
            <h3 className='centered-title'>Short Mode</h3>
            <p className='centered-text'>
              A short response GPT4o chat interface with specialized
              database search integration for your jurisdiction. In other words
              it is like chat gpt but with "RAG". This system creates legal texts
              with richer citations to statutes and case law.
            </p>

          </div>
          <div className='flex-item grey-box'>
            <h3 className='centered-title'>Long Mode</h3>
            <p className='centered-text'>
              THIS is complete long document generation with downloads to your
              machine--GPT4o with specialized RAG integration for your
              jurisdiction. These are competent, well organized, complete
              documents of the length specified in the prompt. For example,
              "Write a 20 page response to this complaint. Ms. X was unaware of
              the wrongful actions and etc. etc. etc." In long mode, Whiteshoe
              will download a well organized 20 page response with specific
              facts woven into jurisdiction-specific legal reasoning.
            </p>
            <div className='process-graphic'>
              <div className='icon-with-text'>
                <img src={whiteDocIcon} alt='White Document Icon' />
                <p className='document-text'>Upload Files</p>
              </div>
              <div className='arrow-and-text'>
                <div className='arrow'>→</div>
                <p className='arrow-text'>
                  "Write a memo/brief/answer/complaint, based on the file."
                </p>
              </div>
              <div className='icon-with-text'>
                <img src={blackDocIcon} alt='Black Document Icon' />
                <p className='document-text'>Output Download</p>
              </div>
            </div>
            <div className='demo-documents'>
              <select
                value={selectedDemo}
                onChange={handleDemoChange}
                className='demo-select'
              >
                <option value='' disabled hidden>
                  View a demo
                </option>
                <option value='alaska_answer'>Alaska Answer (English)</option>
                <option value='Trump_Answer'>
                  New York Answer Donald Trump (English)
                </option>
                <option value='Jarkesy_Brief'>
                  SCOTUS Brief SEC (English)
                </option>
                <option value='Heard_Answer'>
                  Virginia Answer Amber Heard (English)
                </option>
                <option value='Castillo_Peru'>
                  Peru Brief Pedro Castillo (Spanish)
                </option>
                <option value='argentina_answer'>Argentina Answer (Spanish)</option>
                <option value='colombia_intergovernment'>
                  Colombia Intergovernment Memo (Spanish)
                </option>
                <option value='turkish_securities'>Turkish Securities Memo (Turkish)</option>
              </select>
              {demoContent && (
                <div
                  className='scrollable-box-long'
                  dangerouslySetInnerHTML={{ __html: demoContent }}
                ></div>
              )}
            </div>
          </div>
        </div>
        <div className="pricing-functionality">
          <h3>Whiteshoe Work</h3>
          <div className="pricing-options">
            <div className="pricing-option">
              <div>
                <h4>Free</h4>
                <ul>
                  <li>Generate up to 10 pages in short mode only.</li>
                </ul>
              </div>
              <button onClick={() => handleSignup()} className="signup-button">
                Sign up
              </button>
            </div>

            <div className="pricing-option">
              <h4>Usage</h4>
              <ul>
                <li>No monthly fee, full access, no rate limit</li>
                <li>
                  Charges $0.25 per 1000 tokens of output. That means about $1 per 12-16
                  pages of output.
                </li>
              </ul>
              <button onClick={() => handleSignup()} className="signup-button">
                Sign up
              </button>
            </div>

            <div className="pricing-option">
              <h4>Subscription</h4>
              <ul>

                <li>$50 monthly, full access, no rate limit</li>
                <li>
                  Charges $0.10 per 1000 tokens. That means about $1 per 30-40 pages of
                  output.
                </li>

              </ul>
              <button onClick={() => handleSignup()} className="signup-button">
                Sign up
              </button>
            </div>
          </div>
        </div>
        <div className='review-section'>
          <h3>Lawyers Review Whiteshoe Reasoning</h3>
          <div className='review-container'>
            <button className='prev-button' onClick={handlePrevReview}>
              ←
            </button>
            <div className='review-content'>
              <div className='review-details'>
                {reviews.length > 0 && (
                  <>
                    <img
                      src={reviews[currentReview].photo}
                      alt={reviews[currentReview].name}
                      className='review-photo'
                    />
                    <div className='review-text'>
                      <h4>{reviews[currentReview].name}</h4>
                      <p className='review-job-title'>
                        {reviews[currentReview].title}
                      </p>
                    </div>
                    <p className='review-comments'>
                      {reviews[currentReview].text}
                    </p>
                  </>
                )}
              </div>
            </div>
            <button className='next-button' onClick={handleNextReview}>
              →
            </button>
          </div>
        </div>
      </main>
    </div>
  );
}

export default WorkPage;
