export const reviews = [
  {
    name: 'Iva',
    title: 'Commercial Attorney',
    text: "Useful platform for drafting briefs and responses to appeals that are rich in references to statutory provisions and case law. The structure of submissions is very transparent. Each submission, regardless of the content, begins with an overview of the context and content of the previously loaded document, so that the user is already familiar with whether the legal reasoning method has been correctly applied and was the document correctly interpreted, which was the case with mine. I noticed the potential of the platform due to the clear connection of legal concepts, which resulted in the fact that the comparative legal analysis in the submissions was carried out without error, within the framework of the requirements, that is, within the framework of the legal areas that I had previously assigned. The briefs are wordy, legal terms are used clearly, and the party's request is highlighted in the right place within the brief itself. There is an evidence citated for every conclusion in the brief.",
    photo: '/assets/iva.png',
  },
  {
    name: 'Ognjen',
    title: 'Legal Assistant',
    text: "Useful platform for drafting briefs and responses to appeals that are rich in references to statutory provisions and case law. The structure of submissions is very transparent. Each submission, regardless of the content, begins with an overview of the context and content of the previously loaded document, so that the user is already familiar with whether the legal reasoning method has been correctly applied and was the document correctly interpreted, which was the case with mine. I noticed the potential of the platform due to the clear connection of legal concepts, which resulted in the fact that the comparative legal analysis in the submissions was carried out without error, within the framework of the requirements, that is, within the framework of the legal areas that I had previously assigned. The briefs are wordy, legal terms are used clearly, and the party's request is highlighted in the right place within the brief itself. There is an evidence citated for every conclusion in the brief.",
    photo: '/assets/ognjen.png',
  },
  {
    name: 'Zeynap',
    title: 'Commercial Attorney',
    text: "I have found this tool to be incredibly valuable for my legal tasks. It seamlessly integrates document review, including complaints and appeals, and offers optimal strategies for specific cases. Moreover, it efficiently identifies relevant cases and precedents, significantly reducing workload. All information is meticulously cited from reliable sources and contains high-quality legal reasoning. Consequently, some sections of the response can be directly integrated into work, while others provide a robust foundation for further development. I have found this tool to be incredibly valuable for my legal tasks. It seamlessly integrates document review, including complaints and appeals, and offers optimal strategies for specific cases. Moreover, it efficiently identifies relevant cases and precedents, significantly reducing workload. All information is meticulously cited from reliable sources and contains high-quality legal reasoning. Consequently, some sections of the response can be directly integrated into work, while others provide a robust foundation for further development. This tool acts as a substitute for a paralegal with comprehensive case knowledge and strong reasoning skills, making it an ideal assistant for any lawyer. I personally commend the quality of the AI's output and the promptness of responses. It accomplishes in minutes what could take hours or days for even the most experienced lawyers.",
    photo: '/assets/zeynap.png',
  },
  {
    name: 'Omar',
    title: 'Lawyer',
    text: 'I have used WhiteShoe and I think it is a good tool for legal assistance. The outputs about Pedro Castillo, demonstrates an evident quality in the analysis capacity due to the clarity and precise discussion of legal concepts and the practical case. Of course, the well-structured summary of events and their legal consequences are just an example of what this language model can develop.',
    photo: '/assets/omar.png',
  },
];
