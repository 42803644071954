import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ErrorBoundary from '../common/ErrorBoundary';
import { AuthContext } from 'provider/auth-provider';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';

import '../../assets/css/styles.css';
import 'react-dropdown/style.css';

const Layout = ({ children }) => {
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  const [feeds, setFeeds] = useState([]);
  const scrollerRef = useRef(null);
  const contentRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleLogout = () => {
    console.log('calling handleLogout');
    setIsLoggedIn(false);
    localStorage.removeItem('token');
    navigate('/login');
  };

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const hideTopBar = () => {
      const topBar = document.querySelector('.goog-te-banner-frame');
      if (topBar) {
        topBar.style.display = 'none';
      }
      const skipTranslate = document.querySelector('.skiptranslate');
      if (skipTranslate) {
        skipTranslate.style.display = 'none';
      }
    };

    window.googleTranslateElementInit = () => {
      if (
        window.google &&
        window.google.translate &&
        window.google.translate.TranslateElement
      ) {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: 'en',
            includedLanguages: 'en,es,fr,de,it,pt,zh-CN,ja,ko,ar',
            layout:
              window.google.translate.TranslateElement.InlineLayout.SIMPLE,
          },
          'google_translate_element'
        );
        hideTopBar();
      } else {
        console.error('Google Translate script did not load properly.');
      }
    };

    const addGoogleTranslate = () => {
      if (!document.getElementById('google-translate-script')) {
        const script = document.createElement('script');
        script.id = 'google-translate-script';
        script.src = `https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit`;
        script.async = true;
        script.onload = () => {
          if (
            window.google &&
            window.google.translate &&
            window.google.translate.TranslateElement
          ) {
            window.googleTranslateElementInit();
          } else {
            console.error(
              'Google Translate script loaded but google.translate is not available.'
            );
          }
        };
        script.onerror = () => {
          console.error('Failed to load the Google Translate script.');
        };
        document.body.appendChild(script);
      }
    };

    addGoogleTranslate();

    const intervalId = setInterval(hideTopBar, 1000);

    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    const fetchFeeds = async () => {
      const proxyUrl = 'https://api.rss2json.com/v1/api.json?rss_url=';
      const feedUrl = 'https://reason.com/volokh/feed/';
      let combinedFeeds = [];

      try {
        const response = await axios.get(`${proxyUrl}${encodeURIComponent(feedUrl)}`);
        console.log(`Fetched data from ${feedUrl}:`, response.data); // Log raw response data
        if (response.data && response.data.items) {
          combinedFeeds = combinedFeeds.concat(response.data.items);
        } else {
          console.warn(`No items found in feed: ${feedUrl}`);
        }
      } catch (error) {
        console.error(`Failed to fetch feed from ${feedUrl}`, error);
      }

      console.log('Combined feeds:', combinedFeeds); // Log combined feeds
      setFeeds(combinedFeeds);
    };

    fetchFeeds();

    const intervalId = setInterval(fetchFeeds, 86400000); // Refresh feeds every 24 hours
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const scroller = scrollerRef.current;
    const content = contentRef.current;
    if (scroller && content) {
      const speed = 1; // Adjust this value to control the scroll speed
      let startPos = scroller.offsetWidth;
      let currentPos = startPos;

      const step = () => {
        try {
          currentPos -= speed;
          if (currentPos <= -content.scrollWidth) {
            currentPos = startPos;
          }
          content.style.transform = `translateX(${currentPos}px)`;
          requestAnimationFrame(step);
        } catch (error) {
          console.error('Scrolling error', error);
        }
      };

      step();
    } else {
      console.error('Scroller or content ref is null');
    }
  }, [feeds]);



  return (
    <ErrorBoundary>
      <div className="layout-container">
        <div className="rss-feed-scroller" ref={scrollerRef}>
          <div className="scrolling-text" ref={contentRef}>
            {feeds.map((item, index) => (
              <span key={index} style={{ marginRight: '50px' }}>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  {item.title}
                </a>
              </span>
            ))}
          </div>
        </div>
        <header className="header">
          <img
            src={`/assets/WS_logo.png`}
            style={{ height: '50px', marginRight: '10px' }}
            alt="Logo"
          />
          <h1 className="header-title">Whiteshoe</h1>
        </header>
        <div className="navbar-container">
          <nav className="navbar">
            <button className="menu-toggle" onClick={() => toggleMenu()}>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </button>
            <ul
              className={
                isMenuOpen ? (windowSize.width > 768 ? '' : 'open') : ''
              }
            >
              <li>
                <Link to="/" onClick={closeMenu}>
                   
                </Link>
              </li>


              {windowSize.width !== undefined && windowSize.width <= 768 && isLoggedIn && (
                <>
                  <li>
                    <Link to="/settings" onClick={closeMenu}>
                      Settings
                    </Link>
                  </li>
                  <li>
                    <Link to="/files" onClick={closeMenu}>
                      Files
                    </Link>
                  </li>
                  <li>
                    <Link to="/account" onClick={closeMenu}>
                      Account
                    </Link>
                  </li>
                  <li>
                    <Link to="/billing" onClick={closeMenu}>
                      Billing
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacypolicy" onClick={closeMenu}>
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        closeMenu();
                        handleLogout();
                      }}
                    >
                      Logout
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </nav>
        </div>
        {isMenuOpen && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: 40,
            }}
            onClick={closeMenu}
          ></div>
        )}

        <div className="main-content">
          <main className="content">{children}</main>
          {isLoggedIn && (
            <aside className="sidebar">
              <div className="sidebar-tab">
                <FontAwesomeIcon icon={faArrowLeft} className="dropdown-icon" />
              </div>
              <ul>
                <li>
                  <Link to="/settings">Settings</Link>
                </li>
                <li>
                  <Link to="/files">Files</Link>
                </li>
                <li>
                  <Link to="/billing">Billing</Link>
                </li>
                <li>
                  <Link to="/account">Account</Link>
                </li>
                <li>
                  <Link onClick={handleLogout}>Logout</Link>
                </li>
              </ul>
            </aside>
          )}
        </div>
        <footer className="footer">
          <p>Copyright © 2024 Web3 Services, LLC. All rights reserved.</p>
          <div
            id="google_translate_element"
            className="translate-element"
          ></div>
        </footer>
      </div>
    </ErrorBoundary>
  );
};

export default Layout;
