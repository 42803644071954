const ComingPage = () => {
  return (
    <div className="coming-page">
      <p>COMING SOON.</p>
      <p>Email david@whiteshoe.ai to join waitlist.</p>
    </div>
  )
}

export default ComingPage;
