export const CSE = {
  'USA': 'd7bb4056a185e46cd',
  'ID': '1200cc5c6864c4c04',
  'CA': 'c755b11e3b8b14732',
  'OR': 'd7ca47fc121774d6e',
  'WA': '070d9771c61784982',
  'AZ': '743497419925c414f',
  'AK': '5776ad1a79017458b',
  'NV': 'f5f89eee684b945d4',
  'HI': 'e380d44a1786d4def',
  'UT': '14aa832e8f86f4481',
  'MT': '8532fcf8e13bf4992',
  'WY': '21bfe5cbc3d514760',
  'CO': 'f7e6686411aaf42b4',
  'NM': '20d4fc8b824cd4751',
  'TX': 'b0a88acb10da74fe0',
  'OK': 'd532fa2acb0ba4a31',
  'KS': '2306bb3dc2974428e',
  'NE': '53e3c0036d21545a1',
  'SD': '21db478c8ab154323',
  'ND': 'b4f5f35bab671460c',
  'MN': '200c87b4823554171',
  'IA': '169d633f45af348ee',
  'MO': 'a246f6af2e3bc4e44',
  'AR': '46c64311464554659',
  'LA': '80dcc09576b4a4530',
  'WI': 'c57a4078dc6734baf',
  'MI': '173d69e66057c443c',
  'IL': 'c2f7817a08d1a418b',
  'IN': '205bf84c89fde4ca9',
  'OH': '92dc378417b274929',
  'KY': '73d9b69a44a9a4d85',
  'TN': '354b5c86a827b428d',
  'AL': '05733d5ebb02349c1',
  'WV': '211d1091a59ab43bc',
  'FL': '3260d34f5022444f4',
  'GA': '65b607f97ff1e4391',
  'SC': 'd4faf57125e8e49b2',
  'NC': '53a3f577e13dc4b26',
  'VA': '564124e9636f444a1',
  'DC': '055511200c8484347',
  'MD': '379a5e71285ac43e0',
  'DE': '0738edbe6d39349fe',
  'PA': '7292c013358ee4e9c',
  'NJ': 'f58eb02e1597b4d2f',
  'NY': '659b694a12d5e443d',
  'CT': 'f0036d2d707be4f89',
  'RI': '41817d9406c9e4a76',
  'MA': '475b40f4dfc8445fd',
  'VT': '47cf28ae6418248b3',
  'NH': '92557b246a4434163',
  'ME': '820074f78b66740e5',
  'New Zealand': '87125780c033f4168',
  'Australia': '51016de37c5d54331',
  'South Korea': '31a925e6ffd57460a',
  'Japan': '2752f0e332fd641df',
  'Singapore': '40045e0f367634cbf',
  'Brunei': '45e9734647990442d',
  'Laos': '653e66a68470545b4',
  'Cambodia': '00b2fae53bb714cb5',
  'Vietnam': 'f4be27ecd4c0449fc',
  'Philippines': '2027f817a5c574cbe',
  'Taiwan': 'f459c04089154485e',
  'Thailand': '076f5b9bf99024d55',
  'Myanmar': '702ae27560c92478d',
  'China': 'c0d2f6e10739e47d4',
  'India': 'a7de0c6de3d914dae',
  'South Africa': '92f3915622c99435d',
  'Botswana': '97cd25e1f5b924d67',
  'Namibia': '5396dc25df297480f',
  'Zimbabwe': '204ad8cf40dfb4cc9',
  'Mozambique': '62cc5f5766cc84a62',
  'Zambia': '74c060afd4fbf46dd',
  'Angola': 'a7c0a6c68100c4156',
  'Burundi': 'e6ec9324893e944b0',
  'Rwanda': 'b022700a171d54f79',
  'Tanzania': 'e5753ac19320d443d',
  'Kenya': '560d946058aa84ec9',
  'Uganda': 'b0b534d8266af477a',
  'DR Congo': '25ca054aea8d24d78',
  'Congo': 'c63d8d5d7d0644504',
  'Gabon': '2667340a853584352',
  'Equatorial Guinea': '36e60e7f03cfb470f',
  'Cameroon': 'f7417c928970c41e6',
  'Central African Republic': 'c6c86be3584f74f13',
  'Somalia': '03d2090b4dd15497d',
  'Ethiopia': '26edb6e8469b4458f',
  'South Sudan': '25479bc73ea0d4fc4',
  'Sudan': '425e6549fa01844f9',
  'Chad': '97f943a660f7f40ba',
  'Bissau': '277014837be2c4f80',
  'Cape Verde': 'e17d6c9b7e2ac48bc',
  'Gambia': 'd64577c71ca4448dc',
  'Togo': '63ea2505026a14db9',
  'Benin': '816deb3d5cf914fb0',
  'Liberia': 'b478b7a173cf540a5',
  'Sierra Leone': 'e2b3f3d5990a64798',
  'Guinea': '357a6df6ee1f24ac1',
  'Ivory Coast': '9055ec238d9e34f9f',
  'Ghana': '62cb36b535bbd4567',
  'Nigeria': 'e68bf3344d2154fb7',
  'Niger': 'b730dd141bf024c29',
  'Burkina Faso': '81589d2d14c8147e0',
  'Mali': 'f31f56ead9a9441b4',
  'Mauritania': 'e55e0b44e785a46fb',
  'Senegal': '77e9fa52f6a124a37',
  'Kazakhstan': 'c679bcbb3b9364613',
  'Kyrgyzstan': '15b7a1acb09a34db9',
  'Uzbekistan': 'c6935f625d73247c4',
  'Turkmenistan': '634221cc5adc94d8c',
  'Tajikistan': 'b3a255666984d407c',
  'Azerbaijan': 'b6a5d851aa678466a',
  'Turkey': '25c7540e25ace40b5',
  'Iran': 'e6a1a67b0abda4952',
  'Afghanistan': 'c01e78f8f1c0343d2',
  'Bangladesh': '874f9e74c7d0041d4',
  'Malaysia': '430bc70e08e114e24',
  'Indonesia': '532e38429e380422b',
  'Egypt': 'a1b4f1c13585d4fbb',
  'Algeria': '80f583ef03a814aad',
  'Morocco': '80998e13c411242f2',
  'Tunisia': '044b1a620e68f44a6',
  'Libya': '1328592ec2f6f42ba',
  'UAE': '313dc449f5b5b4cb0',
  'Yemen': 'b10b782e0409b49c0',
  'Saudi': '026ca8809d004421b',
  'Oman': '167dd5bfb1cf94035',
  'Qatar': '16a2e651f962c420b',
  'Bahrain': '457abb06123c64822',
  'Kuwait': 'b440709eebc7441ed',
  'Jordan': '235082956da6144dc',
  'Lebanon': 'f004cbfbbeb704701',
  'Syria': '904af03118bf44b02',
  'Iraq': 'a3e1f10c0bc5b4d6f',
  'Israel': 'd33f63fc7cea0436c',
  'Ireland': '963ccfe637f6f48b8',
  'UK': '81c473ba308874f5d',
  'Iceland': '22e4bae6ef6f5484a',
  'Spain': '225e88bf1c55246c5',
  'France': '8679eb352c41d4788',
  'Switzerland': 'c651e2f65787c47da',
  'Germany': 'f40a2792afcaa447d',
  'Austria': '00c40c2dee4e74b4b',
  'Italy': '25acfba45275840c0',
  'Belgium': '24740485c9e224643',
  'Netherlands': 'd51624edf86b341a8',
  'Denmark': 'a5e3f616c642042e5',
  'Norway': '35f1d8975cd3a4609',
  'Sweden': '30f831bc978184398',
  'Finland': '801fea0b535e64225',
  'Estonia': '06c79d307e7c84a9a',
  'Latvia': '66434d86bb9584cf3',
  'Lithuania': '623c1be1dad72448e',
  'Poland': 'b6377463815a84b1b',
  'Belarus': '90187a15e99fe4908',
  'Czechia': 'b26f7a63e592e4f7f',
  'Slovakia': 'f3d9f4c0b7670459a',
  'Hungary': '734d0357a1c1440b5',
  'Slovenia': '93c9109e8a11a4e0b',
  'Croatia': '56955e1e98b5d470f',
  'Bosnia': '042592812288d4feb',
  'Serbia': '855429f88ae234e93',
  'Romania': 'd6019aa6a91e845dd',
  'Moldova': '64c1c78f2e7de49bd',
  'Ukraine': '3079f2b6f65af43ef',
  'Bulgaria': '126714e957efa4071',
  'Montenegro': 'e5b452a1dbe2248e3',
  'Albania': '71e04eeb84db140f6',
  'Macedonia': '7206d0bbcdbad4c44',
  'Greece': 'f717ae7b1e425492e',
  'Russia': 'b0d44bfdaa1634987',
  'Georgia': '031e35af618c14c2b',
  'Argentina': 'a0326d8b803294fb9',
  'Colombia': '267dae035d7f14de8',
  'Pakistan': '444215f6f7e6e4340',
  'Canada': '65601f3d41d3f4483',
  'Mexico': '04529c856122f4fcd',
  'Dominican': '33e7b354287a24ce9',
  'Guatemala': '63ab8fc90aded4ac7',
  'El Salvador': '47606ae3c42c84368',
  'Belize': '4470e6d80bb674c8a',
  'Nicaragua': '42776bbc4518c43ca',
  'Honduras': 'a3e47168171684002',
  'Costa Rica': 'd455d0fbbe39a4f70',
  'Panama': '768686f7a13334c85',
  'Ecuador': '117978b9cb12748d9',
  'Peru': '92521dc21162c41ec',
  'Bolivia': '16992988fcd1149d7',
  'Paraguay': '33560329ed3a24aa7',
  'Uruguay': '246e2fdbe070a4313',
  'Chile': '911e1357bf53b485d',
  'Brazil': '77145d35b75b94602',
  'Portugal': '2764e664e829a4f18',
};

export default CSE;
