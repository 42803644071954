export const getApiBaseUrl = () => {
  if (process.env.NODE_ENV === 'production') {
    const hostname = window.location.hostname;
    if (hostname === process.env.REACT_APP_FRONTEND_URL) {
      return 'https://www.whiteshoe.net';
    } else if (hostname === process.env.REACT_APP_BACKEND_URL) {
      return 'https://www.whiteshoe.net';
    }
  }
  return process.env.REACT_APP_API_BASE_URL;
};

export const parseMarkdown = (text) => {
  text = text.replace(/^###### (.*$)/gim, '<h6>$1</h6>');
  text = text.replace(/^##### (.*$)/gim, '<h5>$1</h5>');
  text = text.replace(/^#### (.*$)/gim, '<h4>$1</h4>');
  text = text.replace(/^### (.*$)/gim, '<h3>$1</h3>');
  text = text.replace(/^## (.*$)/gim, '<h2>$1</h2>');
  text = text.replace(/^# (.*$)/gim, '<h1>$1</h1>');
  text = text.replace(/\[([^[\]]+)]\(([^)]+)\)/g, '<a href="$2">$1</a>');
  text = text.replace(/\*\*([^*]+)\*\*/g, '<strong>$1</strong>');
  text = text.replace(/\*([^*]+)\*/g, '<em>$1</em>');
  text = text.replace(/^ (.*$)/gim, '<blockquote>$1</blockquote>');
  text = text.replace(
    /```\n([\s\S]*?)\n```/gim,
    '<pre><code>$1</code></pre>'
  );
  text = text.replace(/`([^`]+)`/gim, '<code>$1</code>');
  text = text.replace(/\n/g, '<br/>');
  return text.trim();
};
