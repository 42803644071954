import React, { useState } from 'react';
import '../../assets/css/styles.css';
import { useNavigate } from 'react-router-dom';

const Video = () => {
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const boxSteps = [
    "You add watermarks for FREE.",
    "We register copyright.",
    "We identify pirates.",
    "We select attorney.",
    "Pirate pays."
  ];

  const popupSteps = [
    {
      title: "You Watermark",
      description: (
        <>
          <ul>
            <li>Use our page to upload your videos and download the watermarked version for free.</li>
            <li>The page uses Pallycon to add invisible traceable watermarks to your videos.</li>
          </ul>
        </>
      ),
    },
    {
      title: "We Register Copyright",
      description: (
        <>
          <p>All your creative work is protected by copyright law, but registered copyrights receive much greater damages for infringement. In exchange for using our service, we:</p>
          <ul>
            <li>Ask for the right to register your copyrights in your name.</li>
            <li>Pay the registration fee and file the registration for the videos we choose to register.</li>
            <li>Request some details from you for the filing.</li>
            <li>If you choose to pay the fee yourself, we'll file for whichever videos you choose to pay for.</li>
            <li>We will need just some simple information for the filing.</li>
          </ul>
        </>
      ),
    },
    {
      title: "We Monitor Web",
      description: (
        <>
          <ul>
            <li>We use Pallycon to monitor social media, bittorrent, and other sites for your content.</li>
            <li>The watermark enables us to trace the IP address of anyone who downloads your content.</li>
          </ul>
        </>
      ),
    },
    {
      title: "We Sue Pirates",
      description: (
        <>
          <p>Legal action against infringers can proceed in different ways:</p>
          <ul>
            <li>Sometimes defendants settle, and sometimes it goes to trial.</li>
            <li>In exchange for using our service, we require that you give us power of attorney to select the attorney to pursue copyright infringement claims on your behalf.</li>
            <li>Once we identify your infringers, we will make them pay.</li>
          </ul>
        </>
      ),
    },
    {
      title: "Get Paid",
      description: (
        <>
          <p>Damages actually collected from your infringers is split as follows:</p>
          <ul>
            <li>The lawyer receives 20%.</li>
            <li>Whiteshoe takes 10%.</li>
            <li>You receive 70% of all damages collected from your infringers.</li>
          </ul>
          <p>For example:</p>
          <ul>
            <li>A person steals 12 copyright registered videos.</li>
            <li>Minimum damages are $750 per infringement on registered videos.</li>
            <li>Infringer pays $9,000, and you receive $6,300</li>
          </ul>
        </>
      ),
    }
  ];

  const handleSignupRedirect = (destination) => {
    localStorage.setItem('redirectAfterLogin', destination);
    navigate('/signup');
  };

  const handleLoginRedirect = () => {
    localStorage.setItem('redirectAfterLogin', 'water');
    navigate('/login');
  };

  const handleNextStep = () => {
    if (currentStep < popupSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      handleSignupRedirect('water');
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div className="video-page">
      <header className="video-header">
        <h1>Video Anti-Piracy</h1>
      </header>
      <section className="video-intro">
        <p>
          Enforcing copyright can be extremely lucrative for creators. Pirates can be forced to pay from several hundred dollars for video thefts <strong>up to hundreds of thousands of dollars</strong> for more substantial piracy.
        </p>
        <section>
          <p className="small-caps-shadow">pirates pay millions every year</p>
        </section>
      </section>
      <section className="graphics-container">
        <div className="graphic-box">
          {boxSteps.map((step, index) => (
            <div className="step" key={index}>
              <div className="step-number">{index + 1}</div>
              <div className="step-content">
                <p>{step}</p>
              </div>
            </div>
          ))}
          <button
            className="signup-button"
            onClick={() => setIsPopupOpen(true)}
          >
            Start
          </button>
        </div>
        <div className="direct-watermark">
          <p>
            Already signed up?{' '}
            <button className="direct-button" onClick={handleLoginRedirect}>
              Click here
            </button>{' '}
            to go directly to the watermarking page.
          </p>
        </div>
      </section>

      {isPopupOpen && (
        <div className="popup-overlay">
          <div className="popup-box">
            <button className="close-button" onClick={() => setIsPopupOpen(false)}>×</button>
            <div className="popup-content">
              <h2>{popupSteps[currentStep].title}</h2>
              <p>{popupSteps[currentStep].description}</p>
              <div className="dots">
                {popupSteps.map((_, index) => (
                  <span
                    key={index}
                    className={`dot ${index === currentStep ? 'active' : ''}`}
                  ></span>
                ))}
              </div>
              <div className="popup-navigation">
                <button
                  className="navigation-arrow"
                  onClick={handlePreviousStep}
                  disabled={currentStep === 0}
                >
                  &larr;
                </button>
                {currentStep < popupSteps.length - 1 ? (
                  <button
                    className="navigation-arrow"
                    onClick={handleNextStep}
                  >
                    &rarr;
                  </button>
                ) : (
                  <button
                    className="navigation-button"
                    onClick={handleNextStep}
                  >
                    Watermark
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Video;
